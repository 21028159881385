import type { FC } from 'react';

// Externals
import { Box, CircularProgress, Typography } from '@mui/material';

interface SplashScreenProps {
	text?: string;
}

const SplashScreen: FC<SplashScreenProps> = (props: SplashScreenProps) => (
	<Box
		sx={{
			alignItems: 'center',
			backgroundColor: 'background.default',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			justifyContent: 'center',
			left: 0,
			p: 3,
			position: 'fixed',
			top: 0,
			width: '100%',
			zIndex: 2000
		}}
	>
		<Box
			alt="FreewayTeam"
			component="img"
			src="/static/icons/brand/logo_dark.svg"
			sx={{ height: 'auto', maxWidth: '100%', width: { xs: 250, sm: 300, md: 350, lg: 400, xl: 450 } }}
		/>
		<CircularProgress
			sx={{
				m: 2
			}}
		/>
		{props.text && <Typography>{props.text}</Typography>}
	</Box>
);

export default SplashScreen;
