import { useEffect, useState } from 'react';
import { SigninCheckResult, useAuth, useSigninCheck } from 'reactfire';

// Store
import { useSelector } from 'components/legacy/store';

// Externals
import toast from 'react-hot-toast';
import { t } from '@lingui/macro';
import { billingApi } from '../services/billing';
import { organizationsApi, useGetMyMembershipsQuery } from '../services/organizations';
import { questsApi } from '../services/quests';
import { taskItemApi } from '../services/taskitems';
import { organizationMemberApi } from '../services/organizationMember';
import { useGetUserProfileQuery, usePostUserProfileExistsMutation, userPrivateApi } from '../services/userProfiles';
import userSlice, { setAuthenticatedUser, setCurrentOrganizationId } from '../slice/user';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { organizationRoleApi } from '../services/organization-roles';
import { adminApi } from '../services/admins';

/**
 * @type {[Boolean, Boolean]} [Connecting, Connected]. {Connecting} indicates whether we are trying to connect the user or not.
 * {Connected} indicates whether we succeeded to connect the user or not.
 *
 * @description If we are still trying to connect the user, it means that we are not sure yet if the user is known or not.
 * {Connected} changes when we are no longer trying to connect the user.
 * After {Connecting} turns to false, we know for sure if the user is connected or not.
 */
const useUserConnected = (): [boolean, boolean] => {
	const language = Number(window.localStorage.getItem('preferred-language'));
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const cookies = new Cookies();

	const [connected, setConnected] = useState<boolean>(false);
	const [connecting, setConnecting] = useState<boolean>(true);
	const dispatch = useDispatch<any>();

	const auth = useAuth();
	const { status, data } = useSigninCheck();

	const [postUserProfileExists] = usePostUserProfileExistsMutation();
	const { data: userProfile, isLoading: loadingUser, isSuccess: userSuccess } = useGetUserProfileQuery({});
	const { data: listOrga, isLoading, isSuccess } = useGetMyMembershipsQuery({});

	useEffect(() => {
		if (status === 'success') {
			if (isLoading && loadingUser) {
			} else {
				if (listOrga?.data?.memberships?.length === 0 && isSuccess && userSuccess) {
					window.localStorage.setItem('organizationId', '0');
				} else if (isSuccess && userSuccess) {
					organizationId === 0 || !listOrga?.data?.memberships?.some((element) => element.id === organizationId)
						? window.localStorage.setItem(
								'organizationId',
								listOrga?.data?.memberships[0]?.id ? listOrga?.data?.memberships[0]?.id : 0
						  )
						: organizationId === userProfile?.data?.userProfile?.currentOrganizationId
						? window.localStorage.setItem('organizationId', organizationId.toString())
						: userProfile?.data?.userProfile?.currentOrganizationId
						? window.localStorage.setItem('organizationId', userProfile?.data?.userProfile?.currentOrganizationId)
						: window.localStorage.setItem(
								'organizationId',
								listOrga?.data?.memberships[0]?.id ? listOrga?.data?.memberships[0]?.id : 0
						  );
				}
			}
			if (data && data.signedIn) {
				(async () => {
					const userProfileExistsResponse = await postUserProfileExists({ email: data.user.email }).unwrap();
					if (!userProfileExistsResponse?.data?.userExists) {
						setConnecting(false);
						setConnected(false);
						auth.signOut();
						data.user.delete();
						toast.error(t`Aucun utilisateur correspondant à cet identifiant.`);
						return;
					}

					setConnected(false);
					setConnecting(true);
					const token = await getUserTokenAsync(data);
					const period = window.localStorage.getItem('periodSelected')
						? window.localStorage.getItem('periodSelected')
						: 30;
					window.localStorage.setItem('accessToken', token);
					window.localStorage.setItem('periodSelected', period.toString());
					const lge = cookies.get('preferred-language') || 'fr';
					language ? '' : window.localStorage.setItem('preferred-language', lge);

					dispatch(setAuthenticatedUser({ isAuthenticated: data.signedIn }));
					dispatch(setCurrentOrganizationId(organizationId));

					dispatch(billingApi.util.invalidateTags(['billing-entity']));
					dispatch(organizationsApi.util.invalidateTags(['OrganizationDetails', 'ListOrganizations']));
					dispatch(questsApi.util.invalidateTags(['ignored-quests', 'organization-quests']));
					dispatch(taskItemApi.util.invalidateTags(['taskitems', 'taskitemsAccomplishment']));
					dispatch(organizationMemberApi.util.invalidateTags(['list-members', 'role']));
					dispatch(userPrivateApi.util.invalidateTags(['user-profile', 'user-pref']));
					dispatch(organizationRoleApi.util.invalidateTags(['role']));
					dispatch(adminApi.util.invalidateTags(['admin-roles', 'admin-accounts', 'admin-permissions']));

					setConnected(true);
					setConnecting(false);
				})();
			} else {
				(async () => {
					setConnecting(true);
					setConnected(false);

					window.localStorage.removeItem('accessToken');
					window.localStorage.removeItem('organizationId');
					dispatch(billingApi.util.resetApiState());
					dispatch(organizationsApi.util.resetApiState());
					dispatch(questsApi.util.resetApiState());
					dispatch(taskItemApi.util.resetApiState());
					dispatch(organizationMemberApi.util.resetApiState());
					dispatch(userPrivateApi.util.resetApiState());
					dispatch(organizationRoleApi.util.resetApiState());
					dispatch(adminApi.util.resetApiState());
					dispatch(userSlice.actions.clear());
					setConnecting(false);
				})();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, dispatch, status, userProfile, listOrga]);

	const getUserTokenAsync = (data: SigninCheckResult) => {
		if (data.signedIn && data.user) return data.user.getIdToken();
		return Promise.resolve('');
	};

	return [connecting, connected];
};

export default useUserConnected;
