import React, { FC } from 'react';
// Externals
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import FormSignIn from '../actions/signin';

const SignInPage: FC = () => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'row',
				width: '100%'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-around',
					borderRadius: '20px',
					mt: 2,
					width: { xs: '90%', md: '60%' },
					height: '85vh',
					background: theme.palette.mode === 'dark' ? theme.palette.background.default : 'rgba(255, 255, 255, 0.22)',
					boxShadow: isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : '0px 4px 30px rgba(0, 0, 0, 0.1)',
					WebkitBackdropFilter: 'blur(0px)',
					border: theme.palette.mode === 'dark' ? 'none' : '0.5px solid rgba(255, 255, 255, 0.42)'
				}}
			>
				<Box
					component="img"
					src={'/static/icons/brand/desktop&mobile.png'}
					alt="Illustration"
					sx={{
						width: '40%',
						height: 'auto',
						display: { xs: 'none', md: 'block' }
					}}
				/>
				{/* Contenu du Box */}

				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					<Image
						alt="iconeFreewayteam"
						height={38}
						width={38}
						src={
							theme.palette.mode === 'dark'
								? '/static/icons/brand/logo-icon-dark-mode.png'
								: '/static/icons/brand/logo-icon.png'
						}
					/>
					<Typography variant="h5" sx={{ mb: 2, mt: { xs: 2, md: 5 }, color: (theme) => theme.palette.text.primary }}>
						Bienvenue
					</Typography>
					<FormSignIn />
				</Box>
			</Box>
		</Box>
	);
};

export default SignInPage;
