import { FC, ReactNode } from 'react';

// Externals
import { Box, useTheme } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface AuthenticationLayoutProps {
	children?: ReactNode;
}

const AuthenticationLayout: FC<AuthenticationLayoutProps> = ({ children }) => {
	const theme = useTheme();
	const environment = process.env.NEXT_PUBLIC_ENV || 'production';
	const router = useRouter();

	return (
		<Box
			sx={{
				backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#F5F5F5',
				zIndex: 15,
				display: 'flex',
				padding: 2,
				flexDirection: 'column',
				height: '100vh',
				width: '100%'
			}}
		>
			<Box sx={{ display: { md: 'flex' }, justifyContent: 'flex-start' }}>
				<Box
					alt="FreewayTeam"
					component="img"
					id="logo_home"
					src={
						theme.palette.mode === 'dark' ? '/static/icons/brand/logo_white.svg' : '/static/icons/brand/logo_dark.svg'
					}
					sx={{
						height: 32,
						width: 'auto'
					}}
				/>
			</Box>
			<Box
				sx={{
					flex: '1 1 auto',
					height: '90vh'
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default AuthenticationLayout;
