import React from 'react';

// Externals

// Components
import Script from 'next/script';
import SignInPage from 'components/legacy/pages/authentication/sign-in';
import AuthenticationLayout from 'components/legacy/layouts/authentication';
import { BrowserRouter } from 'react-router-dom';
import GuardedPage from 'components/GuardedPage';
import { NoSsr } from '@mui/material';
import AuthGuard from 'components/legacy/components/gards/AuthGuard';
import HomepageGuard from 'components/legacy/components/gards/homepage-guard';
import useUserConnected from 'components/legacy/hooks/useUserConnected';
import SplashScreen from 'components/legacy/screens/SplashScreen';

// Utils

// I18N

// import plural rules for all locales

// Store

const HomePage = () => {
	return (
		<NoSsr>
			<BrowserRouter>
				<HomepageGuard>
					<AuthenticationLayout>
						<SignInPage />
					</AuthenticationLayout>
				</HomepageGuard>
			</BrowserRouter>
		</NoSsr>
	);
};

export const getServerSideProps = async (context) => {
	return {
		props: {}
	};
};

export default HomePage;
